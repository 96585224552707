import { Button } from '@therms/atalaya'
import React from 'react'
import * as Sentry from '@sentry/react'

export class ErrorBoundary extends React.Component<{
  children: React.ReactNode
  onError?: (error: Error, errorInfo?: any) => void
  onResetError?: () => void
}> {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('React#ErrorBoundary', error, errorInfo)
    Sentry.captureException(error, { extra: errorInfo })
    this.props.onError?.(error, errorInfo)
  }

  reset = () => {
    console.info('React#ErrorBoundary resetting')
    if (this.props.onResetError) {
      this.props.onResetError()
    } else {
      this.setState({ hasError: false })
    }
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return (
        <div className="space-small text-danger">
          <div className="flex">
            <div>An Error Occurred</div>
          </div>
          {this.props.onResetError && (
            <div className="mt-sm">
              <Button onClick={this.reset} outline variant="critical">
                Reload
              </Button>
            </div>
          )}
        </div>
      )
    }

    return this.props.children
  }
}
