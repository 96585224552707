import React from 'react'
import { MenuLink } from '@src/modules/Layout/shared/MenuLink'
import { USERS_ROUTES } from '../../constants'
import { Inline, Stack } from '@therms/atalaya'

function UsersMenuLink() {
  return (
    <MenuLink to={USERS_ROUTES.__ROOT__}>
      <span className="text-base">Users</span>
    </MenuLink>
  )
}

export { UsersMenuLink }
