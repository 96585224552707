import axios from 'axios'
import { getLocalAuth } from './local-auth'
import {GLOBALS} from "../../build-env"

const execApiService = axios.create({
  baseURL: GLOBALS.EXEC_API_URL ?? 'https://devexecutive.quicktask.us/api' //'http://localhost:4050',
})

// Add Authorization header to every request
execApiService.interceptors.request.use(
  (config) => {
    const authToken = getLocalAuth()

    if (authToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = authToken
    }

    return config
  },
  (error) => Promise.reject(error),
)

export { execApiService }
