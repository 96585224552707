type BUILD_ENV = {
  BUILD_DATE: string
  DEPENDENCY_VERSIONS: string
  EXEC_API_URL?: string
}

declare const BUILD_ENV: BUILD_ENV

export const GLOBALS: BUILD_ENV = {
  BUILD_DATE: BUILD_ENV.BUILD_DATE,
  EXEC_API_URL: BUILD_ENV.EXEC_API_URL,
  DEPENDENCY_VERSIONS: BUILD_ENV.DEPENDENCY_VERSIONS,
}
