import React from 'react'
import { LoginForm } from './LoginForm'
import { Stack } from '@therms/atalaya'

interface LoginScreenProps {
  onAuthSuccess: (jwt: string) => void
}

function LoginScreen({ onAuthSuccess }: LoginScreenProps) {
  return (
    <Stack alignX="center" alignY="center" paddingTop="xxl" marginTop="xxl">
      <div className="w-96">
        <LoginForm onAuthSuccess={onAuthSuccess} />
      </div>
    </Stack>
  )
}

export { LoginScreen }
