import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

function MenuLink({ children, to }) {
  return (
    <NavLink
      activeClassName="bg-gray-900 text-white"
      to={to}
      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      {children}
    </NavLink>
  )
}

MenuLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
}

export { MenuLink }
