import { execApiService } from './api'
import { APITypes } from './api-types'
import { AxiosResponse } from 'axios'

export const ExecApiRequests = {
  authenticate: async (email: string, password: string) =>
    execApiService.post('/auth/authenticate', {
      email,
      password,
    }),

  createRegion: async (
    body: APITypes.CreateRegion['body'],
  ): Promise<AxiosResponse<APITypes.CreateRegion['response']>> =>
    execApiService.post('/regions/create-region', body),

  createSite: async (
    body: APITypes.CreateSite['body'],
  ): Promise<AxiosResponse<APITypes.CreateSite['response']>> =>
    execApiService.post('/sites/create-site', body),

  createUser: async (
    body: APITypes.CreateUser['body'],
  ): Promise<AxiosResponse<APITypes.CreateUser['response']>> =>
    execApiService.post('/users/create-user', body),

  getBrands: async () => execApiService.get('/brands/list'),

  getIdentity: async () => execApiService.get('/auth/who-am-i'),

  getQuickTaskSitesList: async (args?: APITypes.QuickTaskSitesList['body']) =>
    execApiService.post('/sites/quicktask-list', args),

  getRegion: async (id: string) => execApiService.get(`/regions/id/${id}`),

  getRegionsList: async (args?: APITypes.RegionsList['body']) =>
    execApiService.post('/regions/list', args),

  getSite: async (id: string) => execApiService.get(`/sites/id/${id}`),

  getSitesList: async (args?: APITypes.SitesList['body']) =>
    execApiService.post('/sites/list', args),

  getSitesGeoList: async (args?: APITypes.SitesGeoList['body']) =>
    execApiService.post('/sites/geo-list', args),

  getSitesScoresTopHighLow: async () =>
    execApiService.get('/sites/scores-top-high-low'),

  getUser: async (id: string) => execApiService.get(`/users/id/${id}`),

  getUsersList: async (args?: APITypes.UsersList['body']) =>
    execApiService.post('/users/list', args),

  logout: async () => execApiService.post('/auth/de-authenticate'),

  setQuickTaskSiteMapping: async ({
    quickTaskSiteId,
    mappedLocalSiteId,
  }: {
    quickTaskSiteId: string
    mappedLocalSiteId: string | undefined
  }) =>
    execApiService.post('/sites/set-quicktask-site-mapping', {
      quickTaskSiteId,
      mappedLocalSiteId,
    }),

  updateRegion: async (
    body: APITypes.UpdateRegion['body'],
  ): Promise<AxiosResponse<APITypes.UpdateRegion['response']>> =>
    execApiService.post('/regions/update-region', body),

  updateUser: async (
    body: APITypes.UpdateUser['body'],
  ): Promise<AxiosResponse<APITypes.UpdateUser['response']>> =>
    execApiService.post('/users/update-user', body),

  updateSite: async (
    body: APITypes.UpdateSite['body'],
  ): Promise<AxiosResponse<APITypes.UpdateSite['response']>> =>
    execApiService.post('/sites/update-site', body),

  assignRegionIdToSite: async ({
    regionId,
    siteId,
  }: {
    regionId: string
    siteId: string
  }): Promise<AxiosResponse> =>
    ExecApiRequests.updateSite({
      site: {
        id: siteId,
        regionId,
      },
    }),

  unAssignRegionIdFromSite: async ({
    siteId,
  }: {
    siteId: string
  }): Promise<AxiosResponse> =>
    ExecApiRequests.updateSite({
      site: {
        id: siteId,
        regionId: '',
      },
    }),
}
