import React from 'react'
import { MenuLink } from '@src/modules/Layout/shared/MenuLink'
import { REGIONS_ROUTES } from '../../constants'
import { Inline } from '@therms/atalaya'

function RegionsMenuLink() {
  return (
    <MenuLink to={REGIONS_ROUTES.__ROOT__}>
      <Inline alignY="center" space="sm">
        <span className='text-base'>Regions</span>
      </Inline>
    </MenuLink>
  )
}

export { RegionsMenuLink }
