import { useEffect, useState } from 'react'
import { ExecApiRequests } from '../../../services/api-requests'
import { getLocalAuth, setLocalAuth } from '../../../services/local-auth'

type ReturnVal =
  | {
      currentUser: {
        brandIds: string[]
        type: 'user'
        userId: string
      }
      isAuthenticated: true
      isVerifyingAuth: boolean
      setAuthToken: (authToken: string | undefined) => void
    }
  | {
      currentUser: undefined
      isAuthenticated: false
      isVerifyingAuth: boolean
      setAuthToken: (authToken: string | undefined) => void
    }

export function useAuth(): ReturnVal {
  const authToken = getLocalAuth()

  const [isVerifyingAuth] = useState(false)
  const [currentUser, setCurrentUser] = useState<{
    brandIds: string[]
    type: 'user'
    userId: string
  }>()

  const handleSetAuthToken = async (_authToken?: string) => {
    if (_authToken) {
      setLocalAuth(_authToken)

      const response = await ExecApiRequests.getIdentity()
      setCurrentUser(response.data)
    } else {
      await ExecApiRequests.logout()

      setLocalAuth('')
      setCurrentUser(undefined)
    }
  }

  const setUser = async () => {
    const response = await ExecApiRequests.getIdentity()
    setCurrentUser(response.data)
  }

  useEffect(() => {
    if (authToken) {
      setUser()
    }
  }, [authToken])

  if (currentUser) {
    return {
      currentUser,
      isAuthenticated: true,
      isVerifyingAuth,
      setAuthToken: handleSetAuthToken,
    }
  }

  return {
    currentUser: undefined,
    isAuthenticated: false,
    isVerifyingAuth,
    setAuthToken: handleSetAuthToken,
  }
}
