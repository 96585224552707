import React from 'react'
import { MenuLink } from '@src/modules/Layout/shared/MenuLink'
import { SITES_ROUTES } from '../../constants'
import { Inline } from '@therms/atalaya'

function SitesMenuLink() {
  return (
    <MenuLink to={SITES_ROUTES.__ROOT__}>
      <Inline alignY="center" space="sm">
        <span className='text-base'>Sites</span>
      </Inline>
    </MenuLink>
  )
}

export { SitesMenuLink }
