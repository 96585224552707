import React from 'react'
import { MenuLink } from '@src/modules/Layout/shared/MenuLink'
import { DASHBOARD_ROUTES } from '../../constants'
import { Inline } from '@therms/atalaya'

function DashboardMenuLink() {
  return (
    <MenuLink to={DASHBOARD_ROUTES.__ROOT__}>
      <Inline alignY="center" space="sm">
        <span className="text-base">Dashboard</span>
      </Inline>
    </MenuLink>
  )
}

export { DashboardMenuLink }
