import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Loading } from '@therms/atalaya'
import { AppLayoutContainer } from './modules/Layout/shared/AppLayoutContainer'
import { ErrorBoundary } from './components/ErrorBoundary'
import { REGIONS_ROUTES } from '@src/modules/Regions/constants'
import { USERS_ROUTES } from './modules/Users/constants'
import { SITES_ROUTES } from './modules/Sites/constants'
import { DASHBOARD_ROUTES } from './modules/Dashboard/constants'

const RegionsRoutes = React.lazy(
  () => import('./modules/Regions/router/Routes'),
)
const UsersRoutes = React.lazy(() => import('./modules/Users/router/Routes'))

const SitesRoutes = React.lazy(() => import('./modules/Sites/router/Routes'))

const DashboardRoutes = React.lazy(
  () => import('./modules/Dashboard/router/Routes'),
)

interface AppRouterProps {
  onLogout: () => void
}

function AppRouter({ onLogout }: AppRouterProps) {
  return (
    <ErrorBoundary onResetError={() => window.location.reload()}>
      <AppLayoutContainer onLogout={onLogout}>
        <ErrorBoundary onResetError={() => window.location.reload()}>
          <React.Suspense fallback={<Loading overlay size="xxl" />}>
            <Switch>
              <Route
                path={DASHBOARD_ROUTES.__ROOT__}
                component={DashboardRoutes}
              />

              <Route path={REGIONS_ROUTES.__ROOT__} component={RegionsRoutes} />

              <Route path={SITES_ROUTES.__ROOT__} component={SitesRoutes} />

              <Route path={USERS_ROUTES.__ROOT__} component={UsersRoutes} />

              <Redirect to={REGIONS_ROUTES.__ROOT__} />
            </Switch>
          </React.Suspense>
        </ErrorBoundary>
      </AppLayoutContainer>
    </ErrorBoundary>
  )
}

export { AppRouter }
